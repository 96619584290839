@font-face {
  font-family: "Comic";
  font-style: normal;
  src: local("ComicSansMS3"), url(./fonts/ComicSansMS3.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: Comic, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
