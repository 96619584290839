.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: white;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
  text-align: center;
}

.App-body {
  background-color: #282c34;
  flex: 9;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 20vmin);
  color: white;
  cursor: pointer;
  padding-top: 10px; /* Adjust the padding to move the content down */
}
